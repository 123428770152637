export default {
    methods: {
		displayItemByRoles (item) {
			if (!item.roles) { return true }
			return this.hasRole(item.roles.split(','))
		},
		displayItemByPermissions (item) {
			if (!item.permissions) { return true }
			return this.hasPermission(item.permissions.split(','))
		},
    },
}
