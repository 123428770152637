<template>
	<v-select
		v-model="mValue"
		:items="items"
		:label="label"
		:multiple="multiple"
		:clearable="clearable && !selectedOnlyAny"
		v-bind="$attrs"
		@input="onInput"
	/>
</template>

<script>
export default {
	props: {
		value: { type: String },
		label: { type: String, default: 'Roles' },
		multiple: { type: Boolean, default: true },
		withAny: { type: Boolean, default: true },
		clearable: { type: Boolean, default: true },
	},
	data () {
		return {
			ANY_OPTION: { text: 'Any', value: 'Any' },
		}
	},
	computed: {
		mValue: {
			get () {
				if (!this.value) {
					return this.withAny ? [this.ANY_OPTION.value] : []
				}
				return this.value.split(',')
			},
			set (v) {
				if (!v.length || (v.length === 1 && v[0] === this.ANY_OPTION.value)) {
					this.$emit('input', null)
				} else {
					this.$emit('input', v.join(','))
				}
			},
		},
		items () {
            const restOptions = this.role_choices.map((item) => ({ text: item, value: item }))
            return this.withAny
				? [{ ...this.ANY_OPTION, disabled: this.selectedOnlyAny }, ...restOptions]
				: restOptions
        },
		selectedOnlyAny () {
			if (!this.withAny) { return false }
			return this.mValue.length === 1 && this.mValue[0] === this.ANY_OPTION.value
		},
	},
	methods: {
		onInput (roles) {
			if (!roles.length) { return }
			if (!roles.includes(this.ANY_OPTION.value)) { return }
			const latestSelected = roles.at(-1)
			if (latestSelected === this.ANY_OPTION.value) {
				this.mValue = []
			} else {
				const anyItemIndex = roles.indexOf(this.ANY_OPTION.value)
				this.mValue = [
					...roles.slice(0, anyItemIndex),
					...roles.slice(anyItemIndex + 1),
				]
			}
		},
	},
}
</script>
