<template>
	<v-card
		v-if="displayTopic"
		class="hub-page-topic-viewer"
		:class="isMobileView && 'hub-page-topic-viewer--mobile'"
	>
		<h5 class="topic-title">{{ title }}</h5>

		<div class="content-row">
			<div class="text-col topic-description">
				<template v-if="description">
					<p v-for="paragraph in description" :key="paragraph">
						{{ paragraph }}
					</p>
				</template>
				<span v-else class="hub-pages__empty-item">
					{{ noDescriptionText }}
				</span>
			</div>

			<div class="action-col topic-action">
				<v-btn color="#46C3B2" class="topic-btn" :disabled="!topic.buttonLink" @click="gotoLink">
					{{ buttonLabel }}
				</v-btn>
			</div>
		</div>
	</v-card>
</template>

<script>
import hubPageMixin from '../mixins/hubPageMixin'
import { displayItemByStatus, NEW_TOPIC_TITLE } from '../config/index'

export default {
	props: {
		topic: { type: Object, default: null },
	},
	mixins: [hubPageMixin],
	data () {
		return {
			noDescriptionText: 'No description available',
		}
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		displayTopic () {
			if (!this.topic) { return false }
			return [
				displayItemByStatus(this.topic),
				this.displayItemByRoles(this.topic),
				this.displayItemByPermissions(this.topic),
			].every(Boolean)
		},
		title () {
			return this.topic?.title || NEW_TOPIC_TITLE
		},
		description () {
			if (!this.topic?.description) { return null }
			return this.topic.description.split('\n')
		},
		buttonLabel () {
			return this.topic?.buttonTitle || 'Go to topic'
		},
	},
	methods: {
		gotoLink () {
			if (!this.topic?.buttonLink) { return }
			const link = this.topic.buttonLink
			const ORIGIN = 'https://hq.quility.com'
			const ORIGIN_API = 'https://hq.quility.com/api/'
			if (link.startsWith(ORIGIN_API)) {
				window.open(link, '_blank')
			} else if (link.startsWith(ORIGIN)) {
				const route = link.replace(ORIGIN, '')
				this.$router.push(route)
			} else if (link.startsWith('/')) {
				this.$router.push(link)
			} else {
				window.open(link, '_blank')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-page-topic-viewer {
	--padding: 2rem 4rem;

	&.hub-page-topic-viewer--mobile {
		--padding: 0.75rem 0.5rem 1rem;
	}

	padding: var(--padding);
	border: 1px solid $border-color;
	box-shadow: none;

	.content-row {
		margin-top: 1rem;
		max-width: 100%;
		display: flex;
		flex-wrap: wrap;
		gap: 2rem 4rem;
	}

	.text-col {
		max-width: 100%;
		flex: 10 1 320px;
	}

	.action-col {
		max-width: 100%;
		flex: 1 0 16rem;
	}

	.topic-title {
		font-size: 1rem;
		line-height: 1.3;
		font-weight: 700;
		color: $color-brand-primary;
	}

	.topic-description {
		font-size: 0.875rem;
		line-height: 1.5;

		p {
			margin-bottom: 0 !important;
		}

		p + p {
			margin-top: 0.75rem !important;
		}
	}

	.topic-action {
		display: flex;
		justify-content: center;
	}

	.topic-btn {
		width: 16rem;
		padding: 0 0.25rem;
		color: $color-white;
		font-weight: 700;
		text-transform: none;
		box-shadow: none;
	}
}
</style>
