<template>
	<v-dialog
		v-model="computedValue"
		:width="width"
		:max-width="maxWidth"
		:fullscreen="fullscreen || $vuetify.breakpoint.xs"
		content-class="hub-pages-dialog"
	>
		<v-card class="info-card">
			<slot name="title">
				<v-card-title class="d-flex flex-nowrap pb-4">
					<h3 class="info-card__title">{{ title }}</h3>
					<v-btn
						icon class="ml-2 align-self-start flex-shrink-0"
						@click="computedValue = false"
					>
						<v-icon>fa fa-times</v-icon>
					</v-btn>
				</v-card-title>
			</slot>

			<div class="linear-loader-container">
				<v-progress-linear v-if="loading" indeterminate />
			</div>

			<slot name="content">
				<v-card-text class="info-card__content" :class="dense && 'px-0'">
					<slot>
						<div v-html="content" />
					</slot>
				</v-card-text>
			</slot>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		value: { type: Boolean, default: false },
		title: { type: String, default: 'Hub Page Dialog' },
		content: { type: String, default: 'Content' },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '1500px' },
		loading: { type: Boolean, default: false },
		fullscreen: { type: Boolean, default: false },
		dense: { type: Boolean, default: false },
	},
	computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-pages-dialog {
	.info-card {
		display: flex;
		flex-direction: column;

		&__title {
			flex-grow: 1;
			color: $color-black;
			margin-bottom: 0;
			font-size: 1.5rem;
			font-weight: bold !important;
			letter-spacing: normal;
		}

		&__content {
			padding-top: 0.5rem;
			padding-bottom: 1.5rem;
			color: $color-black !important;
		}
	}

	&:not(.v-dialog--fullscreen) {
		.info-card {
			border-radius: $border-radius-small;
		}
	}
}
</style>
