<template>
	<HubPagesDialog
		v-model="computedValue"
		title="Hub Page Editor"
		:width="width"
		:max-width="maxWidth"
	>
		<HubPageEditor
			:page="page"
			:mode="mode"
			@cancel="computedValue = false"
			@saved="$emit('saved', $event)"
		/>
	</HubPagesDialog>
</template>

<script>
import HubPagesDialog from './HubPagesDialog.vue'
import HubPageEditor from './HubPageEditor.vue'

import { EDITOR_MODE } from '../config/index'

export default {
    components: {
		HubPagesDialog,
		HubPageEditor,
	},
	props: {
		value: { type: Boolean, default: false },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '1500px' },

		page: { type: Object, default: null },
		mode: { type: String, default: EDITOR_MODE.new },
	},
    computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
	},
}
</script>
