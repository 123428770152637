<template>
	<v-select
		v-model="mValue"
		v-bind="$attrs"
		:items="items"
		:label="label"
		multiple
		:clearable="clearable && !selectedOnlyAll"
		@input="onInput"
	/>
</template>

<script>
export default {
	props: {
		value: { type: String },
		label: { type: String, default: 'Division' },
		clearable: { type: Boolean, default: true },
	},
	data () {
		return {
			ALL_OPTION: { text: 'All', value: 'All' },
		}
	},
	computed: {
		mValue: {
			get () {
				if (!this.value) {
					return [this.ALL_OPTION.value]
				}
				return this.value.split(',')
			},
			set (v) {
				if (!v.length || (v.length === 1 && v[0] === this.ALL_OPTION.value)) {
					this.$emit('input', this.ALL_OPTION.value)
				} else {
					this.$emit('input', v.join(','))
				}
			},
		},
		divisionOptions () {
			return this.raw_divisions.map((item) => ({ text: item, value: item }))
		},
		items () {
            return [{ ...this.ALL_OPTION, disabled: this.selectedOnlyAll }, ...this.divisionOptions]
        },
		selectedOnlyAll () {
			return this.mValue.length === 1 && this.mValue[0] === this.ALL_OPTION.value
		},
	},
	methods: {
		onInput (divisions) {
			if (!divisions.length) { return }
			if (!divisions.includes(this.ALL_OPTION.value)) {
				// if (divisions.length === this.divisionOptions.length) {
				// 	this.mValue = [this.ALL_OPTION.value]
				// }
				return
			}
			const latestSelected = divisions.at(-1)
			if (latestSelected === this.ALL_OPTION.value) {
				this.mValue = [this.ALL_OPTION.value]
			} else {
				const allItemIndex = divisions.indexOf(this.ALL_OPTION.value)
				this.mValue = [
					...divisions.slice(0, allItemIndex),
					...divisions.slice(allItemIndex + 1),
				]
			}
		},
	},
}
</script>
