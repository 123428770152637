<template>
	<v-card
		v-if="displayCategory"
		class="hub-page-category-viewer"
		:class="isMobileView && 'hub-page-category-viewer--mobile'"
	>
		<h3 class="category-title">{{ title }}</h3>

		<div class="category-topics">
			<template v-if="topics.length">
				<TopicViewer
					v-for="topic in topics" :key="getIdentifier(topic)"
					:topic="topic"
				/>
			</template>
			<span v-else class="hub-pages__empty-item">
				{{ noTopicsText }}
			</span>
		</div>
	</v-card>
</template>

<script>
import TopicViewer from './TopicViewer.vue'
import hubPageMixin from '../mixins/hubPageMixin'
import { displayItemByStatus, NEW_CATEGORY_TITLE, getIdentifier } from '../config/index'

export default {
	components: {
		TopicViewer,
	},
	mixins: [hubPageMixin],
	props: {
		category: { type: Object, default: null },
	},
	data () {
		return {
			noTopicsText: 'No topics available',
		}
	},
	computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		displayCategory () {
			if (!this.category) { return false }
			return displayItemByStatus(this.category)
		},
		title () {
			return this.category?.title || NEW_CATEGORY_TITLE
		},
		topics () {
			if (!this.category?.topics) { return [] }
			return this.category.topics
				.filter(displayItemByStatus)
				.filter(this.displayItemByRoles)
				.filter(this.displayItemByPermissions)
		},
	},
	methods: {
		getIdentifier,
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

.hub-page-category-viewer {
	--padding: 2rem 4rem;
	--font-size: 1.5rem;
	--margin: 2rem;

	&.hub-page-category-viewer--mobile {
		--padding: 0.5rem;
		--font-size: 1.125rem;
		--margin: 1.5rem;
	}

	padding: var(--padding);
	border: 1px solid $border-color;
	box-shadow: none;

	.category-title {
		margin-bottom: 0;
		font-size: var(--font-size);
		line-height: 1.3;
		font-weight: 700;
	}

	.category-topics {
		margin-top: 1rem;

		div + div {
			margin-top: var(--margin);
		}
	}
}
</style>
