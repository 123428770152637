<template>
	<HubPagesDialog
		v-model="computedValue"
		title="Hub Page preview"
		:width="width"
		:max-width="maxWidth"
		:fullscreen="fullscreen || $vuetify.breakpoint.xs"
		dense
	>
		<template #content>
			<div class="hub-page-previewer-content">
				<HubPageRenderer
					:page="page"
					:mode="mode"
					:disable-copy="disableCopy"
					:disable-edit="disableEdit"
					@copy-page="$emit('copy-page', $event)"
					@edit-page="$emit('edit-page', $event)"
				/>
			</div>
			<v-card-actions class="pa-6">
				<v-spacer />
				<v-btn depressed @click="computedValue = false">Back</v-btn>
			</v-card-actions>
		</template>
	</HubPagesDialog>
</template>

<script>
import HubPagesDialog from './HubPagesDialog.vue'
import HubPageRenderer from './HubPageRenderer.vue'

import { PAGE_VIEW_MODE } from '../config/index'

export default {
    components: {
		HubPagesDialog,
		HubPageRenderer,
	},
	props: {
		value: { type: Boolean, default: false },
		width: { type: String, default: '95%' },
		maxWidth: { type: String, default: '1500px' },
		fullscreen: { type: Boolean, default: true },

		page: { type: Object, default: null },
		mode: { type: String, default: PAGE_VIEW_MODE.edit },
		disableCopy: { type: Boolean, default: false },
		disableEdit: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
	},
    computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
	},
}
</script>

<style lang="scss">
@import '../style/style.scss';

.hub-page-previewer-content {
	flex-grow: 1;
	padding: 1.5rem 0 2rem;
	border-top: thin solid $border-color;
	border-bottom: thin solid $border-color;
	background-color: #F7F7F7;
}
</style>
