import moment from 'moment'

export const NEW_ID = ''
export const NEW_CATEGORY_TITLE = 'New Category'
export const NEW_TOPIC_TITLE = 'New Topic'

export const ALL_OPTION = '-All-'

export const getInnerId = () => `${new Date().getTime()}-${Math.round(Math.random() * 10000)}`

export const getNewItem = (item) => ({ ...item, _id: getInnerId() })

export const getIdentifier = (item) => {
	if (!item) { return undefined }
	return item.id !== NEW_ID ? item.id : item._id
}

export const copyPageItem = (item) => {
	if (!item) { return {} }
	const categories = item.categories?.map((category) => ({
		...category,
		topics: category.topics?.map((topic) => ({ ...topic })) || [],
	})) || []
	return { ...item, categories }
}

export const createNewFromPageItem = (item) => {
	if (!item) { return {} }
	const categories = item.categories?.map((category) => ({
		...category,
		id: NEW_ID,
		_id: getInnerId(),
		topics: category.topics?.map((topic) => ({ ...topic, id: NEW_ID, _id: getInnerId() })) || [],
	})) || []
	return { ...item, id: NEW_ID, _id: getInnerId(), title: '', slug: '', categories }
}

export const removeInnerIdentifier = (pageItem) => {
	if (!pageItem) { return pageItem }
	const { _id, ...page } = pageItem
	const categories = page.categories?.map((categoryItem) => {
		const { _id, ...category } = categoryItem
		const topics = category.topics?.map((topicItem) => {
			const { _id, ...topic } = topicItem
			return topic
		}) || []
		return { ...category, topics }
	}) || []
	return { ...page, categories }
}

export const dateRule = (format = 'YYYY-MM-DD') => {
	return (v) => {
		if ([undefined, null, ''].includes(v)) {
			return true
		}
		if (moment(v, format, true).isValid()) {
			return true
		}
		return 'Enter a valid date'
	}
}

export const displayItemByStatus = (item) => {
	if (item.status !== STATUS.publish) { return false }
	if (item.publishDate && moment().isBefore(item.publishDate, 'day')) { return false }
	if (item.endDate && moment().isAfter(item.endDate, 'day')) { return false }
	return true
}

export const EDITOR_MODE = {
	new: 'new',
	edit: 'edit',
	copy: 'copy',
}

export const PAGE_VIEW_MODE = {
	view: 'view',
	edit: 'edit',
}

export const defaultOptions = {
	sortBy: ['title'],
	sortDesc: [true],
	page: 1,
	itemsPerPage: 25,
}

export const defaultAuditOptions = {
	sortBy: ['updated_at'],
	sortDesc: [true],
	page: 1,
	itemsPerPage: 25,
}

export const STATUS = {
	publish: 'Publish',
	draft: 'Draft',
	trash: 'Trash' ,
}
