<template>
    <v-menu
		v-model="dateMenu"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y max-width="290px" min-width="290px"
	>
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
				ref="date-field"
				v-bind="attrs"
				v-on="on"
				v-model="dateFormatted"
				:rules="fieldRules"
				:label="label"
				:disabled="disabled"
				:prepend-icon="icon"
				:color="color"
				:clearable="clearable"
				:hint="hint"
				persistent-hint
				@click:prepend="showDatePicker"
				@input="update"
			/>
        </template>

        <v-date-picker
			v-model="date"
			:color="color"
			no-title
			@input="update"
		/>
    </v-menu>
</template>

<script>
import moment from 'moment'

export default {
	inject: {
		form: { default: null }
	},
	props: {
		value: { type: [String, null] },
		label: { type: String, default: 'Date' },
		rules: { type: Array, default: () => [] },
		hint: { type: String, default: 'YYYY-MM-DD format' },
		format: { type: String, default: 'YYYY-MM-DD' },
		icon: { type: String, default: 'fas fa-calendar-alt' },
		disabled: { type: Boolean, default: false },
		clearable: { type: Boolean, default: true },
		color: { type: String },
	},
    data () {
        return {
            dateMenu: false,
            date: undefined,
            dateFormatted: undefined,
        }
    },
	created () {
		this.dateFormatted = this.value
	},
    computed: {
        fieldRules () {
            return [this.dateRule(this.format), ...this.rules]
        },
    },
    watch: {
		value (newV) {
            this.dateFormatted = newV
        },
		dateMenu () {
			if (!this.dateMenu) {
				this.date = undefined
			}
		},
    },
    methods: {
        showDatePicker () {
			this.setPickerDate()
            this.dateMenu = true
        },
		setPickerDate () {
			this.date = moment(this.dateFormatted).isValid() ? this.dateFormatted : undefined
		},
        update (v) {
            if ([undefined, null, ''].includes(v)) {
                this.$emit('input', null)
                this.dateFormatted = null
				return
            }
            this.$emit('input', v)
            this.dateFormatted = v
            this.dateMenu = false
        },
		dateRule (format) {
			return (v) => {
				if ([undefined, null, ''].includes(v)) {
					return true
				}
				if (moment(v, format, true).isValid()) {
					return true
				}
				return 'Enter a valid date'
			}
		},
    },
}
</script>
