import api from '@/store/API/QuilityAPI'

import { removeInnerIdentifier } from '../config/index'

type Options = {
	division: string,
	status: string,
	itemsPerPage: number,
	page: number,
	search: string,
	sortBy: string[],
	sortDesc: boolean[],
	needAvailableFilters: boolean,
}

type Topic = {
	id: string | number,
	title: string,
	description: string,
	buttonTitle: string,
	buttonLink: string,
	roles: string | null,
	permissions: string | null,
	status: string,
	publishDate: string | null,
	endDate: string | null,
}

type Category = {
	id: string | number,
	title: string,
	status: string,
	publishDate: string | null,
	endDate: string | null,
	topics: Topic[],
}

type Page = {
	id: string | number,
	title: string,
	slug: string,
	division: string | null,
	status: string,
	description: string,
	categories: Category[],
	lastChangeBy: string,
	dateCreated: string | null,
	dateUpdated: string | null,
}

type PageAudit = {
	id: string | number,
	hub_page_id: string | number,
	user_id: string | number,
	slug: string,
	action: string,
	change_made_by: string,
	created_at: string | null,
	updated_at: string | null,
	hub_page_data: Page,
}

type Response = {
	data: Page | Page[] | PageAudit[],
	meta?: {
		total: number,
		filters: Record<string, string[] | (string | Record<string, string>)[]>,
	}
}

type ErrorResponse = {
	error: Error,
	message?: string,
}

export const getHubPages = async (params: Options) => {
	const cancelQRequestName = 'getHubPages'
	api.cancelQRequest(cancelQRequestName)
	return api.getRequest('/api/private/hub_pages', params, cancelQRequestName)
	    .then((json) => {
			return json as Response
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const getHubPage = async (id: string | number) => {
	const cancelQRequestName = 'getHubPage'
	api.cancelQRequest(cancelQRequestName)
	return api.getRequest(`/api/private/hub_pages/${id}`, null, cancelQRequestName)
	    .then((json) => {
			return json.data as Page
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const getHubPageBySlug = async (slug: string) => {
	const cancelQRequestName = 'getHubPageBySlug'
	api.cancelQRequest(cancelQRequestName)
	return api.getRequest(`/api/private/hub_pages/slug/${slug}`, null, cancelQRequestName)
	    .then((json) => {
			return json.data as Page
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const createHubPage = async (page: Page) => {
	const params = { ...removeInnerIdentifier(page) }
	return api.postRequest(`/api/private/hub_pages`, params)
	    .then((json) => {
			return json.data as Page
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const updateHubPage = async (page: Page) => {
	const params = { ...removeInnerIdentifier(page) }
	return api.putRequest(`/api/private/hub_pages/${page.id}`, params)
	    .then((json) => {
			return json.data as Page
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const deleteHubPage = async (id: string | number) => {
	return api.deleteRequest(`/api/private/hub_pages/${id}`)
	    .then(() => {
			return { id }
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}

export const getHubPageAudit = async (id: number | string) => {
	const cancelQRequestName = 'getHubPageAudit'
	api.cancelQRequest(cancelQRequestName)
	return api.getRequest(`/api/private/hub_page_versions/${id}`, cancelQRequestName)
	    .then((json) => {
			return json as Response
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message || err.msg
	        return { error: err, message } as ErrorResponse
	    })
}
